import {
  Modal,
  Input,
  Button,
  Stack,
  Checkbox,
  Badge,
  Box,
  Title,
  Text,
  ActionIcon,
  rem,
  Loader,
  Group,
  Paper,
  createStyles,
} from "@mantine/core";
import { useContext, useState } from "react";
import storeContext from "../../store/store";
import { saveDemographicsSettings } from "../../map-utils/filterbarAPI";
import { useAuth } from "../../hooks/useAuth";
import { HoverCard } from "@mantine/core";
import { X } from "tabler-icons-react";
import { setCheckedOutCatalog } from "../FiltersBar";
import { showNotification } from "@mantine/notifications";

const useStyles = createStyles((theme) => ({
  modal: {
    '.mantine-Modal-header': {
      marginBottom: 0,
    },
  },
  section: {
    backgroundColor: theme.white,
    padding: theme.spacing.md,
    borderRadius: theme.radius.md,
    border: `1px solid ${theme.colors.gray[2]}`,
    maxHeight: '300px',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      background: theme.colors.gray[1],
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.colors.gray[3],
      borderRadius: '4px',
      '&:hover': {
        background: theme.colors.gray[4],
      },
    },
  },
  pillsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing.xs,
  },
  hoverCardDropdown: {
    padding: theme.spacing.xs,
    backgroundColor: "#FFF8F0",
    borderRadius: theme.radius.sm,
    maxHeight: '300px',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.colors.orange[2],
      borderRadius: '3px',
      '&:hover': {
        background: theme.colors.orange[3],
      },
    },
  },
  filterGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(120px, 1fr))',
    gap: theme.spacing.xs,
    width: '100%',
    maxWidth: '600px',
  },
  badge: {
    cursor: 'pointer',
    transition: 'all 0.15s ease',
    padding: '6px 12px',
    margin: '2px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&:hover': {
      transform: 'translateY(-1px)',
      boxShadow: theme.shadows.xs,
    },
  },
  categoryBadge: {
    fontSize: theme.fontSizes.sm,
    letterSpacing: '0.3px',
    backgroundColor: theme.colors.gray[0],
    border: `1px solid ${theme.colors.gray[3]}`,
    color: theme.colors.gray[7],
  },
  filterBadge: {
    backgroundColor: theme.colors.orange[0],
    color: theme.colors.orange[9],
    border: 'none',
  },
  hoverCard: {
    width: 'auto',
    maxWidth: '500px',
  },
  footer: {
    borderTop: `1px solid ${theme.colors.gray[2]}`,
    paddingTop: theme.spacing.md,
  },
  actionButton: {
    backgroundColor: theme.white,
    border: `1px solid ${theme.colors.orange[6]}`,
    color: theme.colors.orange[6],
    '&:hover': {
      backgroundColor: theme.colors.orange[0],
    },
  }
}));

interface FilterTable {
  sub_categories: {
    sub_category_title: string;
    filters: any[];
  }[];
}


const FilterSaveModal: React.FC = () => {
  const { state, dispatch } = useContext<any>(storeContext);
  const [filterName, setFilterName] = useState("");
  const [isDefault, setIsDefault] = useState(false);
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();
  const { classes } = useStyles();


  const generatePills = (filterList: FilterTable[]) => {
    if (filterList.length < 1) {
      return (
        <Paper className={classes.section} p="md">
          <Text color="dimmed" size="sm" align="center">No filters selected</Text>
        </Paper>
      );
    }

    return (
      <Paper className={classes.section}>
        <div className={classes.pillsContainer}>
          {filterList.flatMap((table) =>
            table.sub_categories.map((subCategory) => (
              <HoverCard
                key={subCategory.sub_category_title}
                position="bottom"
                shadow="sm"
                openDelay={300}
                closeDelay={150}
                
              >
                <HoverCard.Target>
                  <Badge 
                    variant="light"
                    className={`${classes.badge} ${classes.categoryBadge}`}
                    size="lg"
                  >
                    {subCategory.sub_category_title}
                    {subCategory.filters.length > 0 && (
                      <Text component="span" size="xs" ml={6} color="dimmed">
                        ({subCategory.filters.length})
                      </Text>
                    )}
                  </Badge>
                </HoverCard.Target>
                <HoverCard.Dropdown>
                  <div className={classes.hoverCardDropdown}>
                    <div className={classes.filterGrid}>
                      {subCategory.filters.map((filter) => (
                        <Badge 
                          key={filter.filter_title}
                          className={`${classes.badge} ${classes.filterBadge}`}
                          size="sm"
                          variant="filled"
                          title={filter.filter_title}
                        >
                          {filter.filter_title}
                        </Badge>
                      ))}
                    </div>
                  </div>
                </HoverCard.Dropdown>
              </HoverCard>
            ))
          )}
        </div>
      </Paper>
    );
  };

  const saveFilterListHandler = async () => {
    const filtersList = state.checkedFilters;
    let pythonDefault = isDefault ? "True" : "False";
    const data = {
      demographicsSettingsJson: JSON.stringify(filtersList),
      settingsName: filterName,
      organisationDefault: pythonDefault,
    };

    try {
      setLoading(true); // Start the loader
      await saveDemographicsSettings(data, `${user.key}`);
      setCheckedOutCatalog(user.key, state, dispatch, "");
      showNotification({
        title: "Success",
        message: "Settings saved successfully!",
        color: "green",
      });
    } catch (error) {
      showNotification({
        title: "Error",
        message: "Failed to save settings. Please try again.",
        color: "red",
      });
    } finally {
      setLoading(false); // Stop the loader
    }
  };

  const handleSaveFilter = () => {
    saveFilterListHandler();
    handleClose();
  };

  const handleClose = () => {
    dispatch({ type: "setDemographicSaveOpen", payload: false });
  };

  return (
    <Modal
      opened={state.demographicSaveVisible}
      onClose={() => handleClose()}
      title={<Title order={4}>Save Demographic Selection</Title>}
      size="lg"
      zIndex={1000}
      className={classes.modal}
      overlayProps={{
        blur: 1,
      }}
    >
      <Stack spacing="lg">
        <Box>
          <Text size="sm" weight={500} m="xs">Selected categories:</Text>
          {generatePills(
            Object.keys(state.checkedFilters).length > 0
              ? state.checkedFilters
              : []
          )}
        </Box>

        <Box>
          <Input
            placeholder="Setting Name"
            value={filterName}
            onChange={(event) => setFilterName(event.currentTarget.value)}
            maxLength={48}
            required
          />
          <Text size="xs" color="dimmed" mt={4}>
            {filterName.length}/48 characters used
          </Text>
        </Box>

        <Box className={classes.footer}>
          <Group position="apart">
            <Checkbox
              checked={isDefault}
              onChange={() => setIsDefault(!isDefault)}
              label="Set as default"
            />
            <Button
              className={classes.actionButton}
              onClick={() => handleSaveFilter()}
              disabled={loading || filterName.trim() === ""}
              rightIcon={loading ? <Loader size="xs" /> : null}
            >
              Save Settings
            </Button>
          </Group>
        </Box>
      </Stack>
    </Modal>
  );
};

export default FilterSaveModal;
