import React, { useState, useContext } from "react";
import { Button, Collapse, Divider, Paper, Text, Box, createStyles, ActionIcon, Stack } from "@mantine/core";
import { ChevronDown, ChevronRight } from "tabler-icons-react";
import SliderGroup from "./SliderGroup";
import { FilterData, FilterDataWithResponses } from "./filtertypes";
import storeContext from "../../store/store";

const useStyles = createStyles((theme) => ({
  filterSection: {
    backgroundColor: theme.white,
    padding: theme.spacing.md,
    borderRadius: theme.radius.md,
    marginBottom: theme.spacing.sm,
    border: `1px solid ${theme.colors.gray[2]}`,
  },
  sectionHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
    borderRadius: theme.radius.sm,
    '&:hover': {
      backgroundColor: theme.colors.gray[0],
    },
  },
  categoryTitle: {
    padding: `${theme.spacing.sm} 0`,
    marginBottom: theme.spacing.xs,
    fontSize: theme.fontSizes.sm,
    fontWeight: 600,
    color: theme.colors.gray[7],
    textTransform: 'uppercase',
    letterSpacing: 0.5,
  },
  sliderContainer: {
    padding: `0`,
  }
}));

// Props type, adjusted to directly use an array of Filters or FiltersWithResponses
interface FilterOptionsProps {
  groupName: string;
  category: string;
  filters: (FilterData | FilterDataWithResponses)[];
}

// Updated Component to use the correct props
const FilterGroup: React.FC<FilterOptionsProps> = ({ groupName, filters, category }) => {
  const [isDropDownOpen, setIsDropDownOpen] = useState(true);
  const { state, dispatch } = useContext<any>(storeContext);
  const { classes } = useStyles();

  filters.forEach((filter: any) => {
    if(filter.top_answers !== undefined) {
      Object.keys(state.appliedFilters).forEach((appliedFilterName: any) => {
        if(state.appliedFilters[appliedFilterName].groupName === groupName) {
          filter.responses.forEach((response: any) => {
            if(response.filter_name === appliedFilterName)
              response.minimumSelected = state.appliedFilters[appliedFilterName].minValue;
              response.maximumSelected = state.appliedFilters[appliedFilterName].maxValue;
          });
        }
      });
    }
    Object.keys(state.appliedFilters).forEach((appliedFilterName: any) => {
      if(appliedFilterName === filter.filter_name) {
        filter.minimumSelected = state.appliedFilters[appliedFilterName].minValue;
        filter.maximumSelected = state.appliedFilters[appliedFilterName].maxValue;
      }
    });
  });

  return (
    <Paper className={classes.filterSection}>
      <div 
        className={classes.sectionHeader}
        onClick={() => setIsDropDownOpen(!isDropDownOpen)}
      >
        <Text weight={600} size="sm">{groupName}</Text>
        <ActionIcon
          variant="subtle"
          color="gray"
          size="sm"
        >
          {isDropDownOpen ? <ChevronDown size={16} /> : <ChevronRight size={16} />}
        </ActionIcon>
      </div>
      <Collapse in={isDropDownOpen}>
        <Stack spacing="xs" className={classes.sliderContainer}>
          {filters.map((filter) => {
            if (filter) {
              if ("responses" in filter) {
                return filter.responses
                  .sort((a, b) => b.area - a.area)
                  .slice(0, filter.top_answers)
                  .map((response) => (
                    <Box key={response.filter_name}>
                      <SliderGroup
                        title={response.filter_name}
                        propertyName={response.filter_name}
                        category={category}
                        groupName={groupName}
                        sliderLabel={(value: number) => `${value}%`}
                        units="%"
                        defaultChecked={true}
                        value={[response.min * 100, response.max * 100]}
                        defaultValue={[response.minimumSelected || response.min * 100, response.maximumSelected || response.max * 100]}
                        changeValue={[response.min * 100, response.max * 100]}
                      />
                    </Box>
                  ));
              } else if(filter.units === '%') {
                return (
                  <Box key={filter.filter_name}>
                    <SliderGroup
                      title={filter.filter_title}
                      propertyName={filter.filter_name}
                      groupName={groupName}
                      category={category}
                      units={filter.units}
                      sliderLabel={(value: number) => `${value}${filter.units}`}
                      defaultChecked={true}
                      value={[filter.min, filter.max]}
                      defaultValue={[filter.minimumSelected || filter.min, filter.maximumSelected || filter.max]}
                      changeValue={[filter.min, filter.max]}
                    />
                  </Box>
                );
              } else {
                return (
                  <Box key={filter.filter_name}>
                    <SliderGroup
                      title={filter.filter_title}
                      propertyName={filter.filter_name}
                      groupName={groupName}
                      category={category}
                      units={filter.units}
                      sliderLabel={(value: number) => `${filter.units}${value}`}
                      defaultChecked={true}
                      value={[filter.min, filter.max]}
                      defaultValue={[filter.minimumSelected || filter.min, filter.maximumSelected || filter.max]}
                      changeValue={[filter.min, filter.max]}
                    />
                  </Box>
                );
              }
            }
            return null;
          })}
        </Stack>
      </Collapse>
    </Paper>
  );
};

export default FilterGroup;
