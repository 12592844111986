import { useState, useContext, useEffect, useMemo } from "react";
import axios from "axios";
import ReactMarkdown from "react-markdown";
import {
  Group,
  Text,
  Button,
  Textarea,
  Loader,
  Paper,
  Stack,
  ScrollArea,
  Box,
  createStyles,
  Avatar,
  Divider,
  ActionIcon,
  Tooltip,
  Chip,
  Badge,
  List,
  Modal,
} from "@mantine/core";
import {
  MessageChatbot,
  Send,
  Refresh,
  ChevronDown,
  Check,
  Clipboard,
  ChartInfographic,
} from "tabler-icons-react";
import storeContext from "../store/store";
import { getNewAPIMapSummaryCSV } from "../map-utils/downloadSummary";
import { useAuth } from "../hooks/useAuth";

const useStyles = createStyles((theme) => ({
  chatContainer: {
    display: "flex",
    flexDirection: "column",
    height: "75vh",
    width: "100%",
    backgroundColor: theme.white,
    borderRadius: theme.radius.md,
  },
  iconContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "end",
  },
  header: {
    padding: theme.spacing.md,
    borderBottom: `1px solid ${theme.colors.gray[2]}`,
    backgroundColor: theme.white,
  },
  messagesArea: {
    flex: 1,
    padding: theme.spacing.md,
    backgroundColor: theme.colors.gray[0],
  },
  message: {
    maxWidth: "85%",
    padding: theme.spacing.sm,
    borderRadius: theme.radius.md,
    marginBottom: theme.spacing.xs,
  },
  userMessage: {
    backgroundColor: theme.colors.orange[1],
    marginLeft: "auto",
  },
  botMessage: {
    backgroundColor: theme.white,
    marginRight: "auto",
  },
  inputArea: {
    padding: theme.spacing.md,
    borderTop: `1px solid ${theme.colors.gray[2]}`,
    backgroundColor: theme.white,
  },
  suggestionButton: {
    backgroundColor: theme.white,
    border: `1px solid ${theme.colors.gray[3]}`,
    "&:hover": {
      backgroundColor: theme.colors.gray[0],
    },
  },
  followUpBar: {
    position: "relative",
    backgroundColor: theme.white,
    borderTop: `1px solid ${theme.colors.gray[2]}`,
    padding: theme.spacing.md,
    boxShadow: theme.shadows.sm,
  },
  followUpScroll: {
    overflowX: "auto",
    overflowY: "hidden",
    whiteSpace: "nowrap",
    padding: theme.spacing.xs,
  },
  followUpCard: {
    display: "inline-block",
    width: "300px",
    marginRight: theme.spacing.sm,
    verticalAlign: "top",
  },
  insightCard: {
    marginBottom: theme.spacing.sm,
    padding: theme.spacing.md,
    backgroundColor: theme.colors.gray[0],
  },
  insightPattern: {
    color: theme.colors.orange[7],
    fontWeight: 500,
    marginBottom: theme.spacing.xs,
  },
  insightAction: {
    color: theme.colors.gray[7],
  },
  minimizeButton: {
    position: "absolute",
    right: theme.spacing.xs,
    top: theme.spacing.xs,
  },
  summaryButton: {
    position: "absolute",
    right: theme.spacing.xl,
    top: theme.spacing.xs,
  },
  summaryModal: {
    backgroundColor: theme.white,
    padding: theme.spacing.md,
  },
}));

interface SuggestedPrompt {
  text: string;
  category: string;
}

interface FollowUpQuestion {
  text: string;
  purpose: string;
}

interface Insight {
  pattern: string;
  action: string;
}

interface InsightResponse {
  insights: Insight[];
  summary: string;
  opportunities: string[];
}

interface FollowUpResponse {
  questions: FollowUpQuestion[];
  tips: string[];
}

const DEFAULT_PROMPTS: SuggestedPrompt[] = [
  { text: "What are the key demographics in this area?", category: "overview" },
  {
    text: "How does this area compare to state averages?",
    category: "comparison",
  },
  { text: "What's unique about this location?", category: "insights" },
  { text: "Tell me about the housing situation here.", category: "housing" },
];

const AIChatWindow = () => {
  const { classes } = useStyles();
  const context = useContext(storeContext);
  const { user } = useAuth();
  const [input, setInput] = useState("");
  const [copied, setCopied] = useState(false);
  const [messages, setMessages] = useState<Array<{ role: string; content: string }>>([
    {
      role: "system",
      content: "You are Bruce, an experienced Australian urban planning consultant with deep expertise in demographic analysis. Use Australian English spelling and terminology (e.g., 'analyse' not 'analyze', 'centre' not 'center'). Maintain a warm and professional tone while providing insightful analysis.",
    },
    {
      role: "assistant",
      content:
        "Hello, I'm Bruce, your urban planning consultant. With years of experience analysing Australian demographics, I'm here to help you understand your selected area. What would you like to know?",
    },
  ]);
  const [loading, setLoading] = useState(false);
  const [loadingPrompts, setLoadingPrompts] = useState(true);
  const [suggestedPrompts, setSuggestedPrompts] = useState<SuggestedPrompt[]>([]);
  const [activeCategory, setActiveCategory] = useState<string>("all");
  const [showFollowUp, setShowFollowUp] = useState(true);
  const [followUpResponse, setFollowUpResponse] = useState<FollowUpResponse | null>(null);
  const [analysisData, setAnalysisData] = useState<InsightResponse | null>(null);
  const [showAnalysis, setShowAnalysis] = useState(false);


  // Get unique categories from prompts
  const categories = useMemo(() => {
    const uniqueCategories = new Set(suggestedPrompts.map((p) => p.category));
    return ["all", ...Array.from(uniqueCategories)];
  }, [suggestedPrompts]);

  // Group prompts by category for better organization
  const groupedPrompts = useMemo(() => {
    return suggestedPrompts.reduce((acc, prompt) => {
      if (!acc[prompt.category]) {
        acc[prompt.category] = [];
      }
      acc[prompt.category].push(prompt);
      return acc;
    }, {} as Record<string, SuggestedPrompt[]>);
  }, [suggestedPrompts]);

  useEffect(() => {
    if (user?.key) {
      fetchSuggestedPrompts();
    }
  }, [user?.key]);

  // Early return if user is not authenticated
  if (!user?.key) {
    return (
      <Paper className={classes.chatContainer}>
        <Box className={classes.header}>
          <Group position="apart" align="center">
            <Group spacing="sm">
              <Avatar color="orange" radius="xl">
                <MessageChatbot size={24} />
              </Avatar>
              <Text size="lg" weight={500}>
                Bruce - AI Urban Planner
              </Text>
            </Group>
          </Group>
        </Box>
        <Box p="xl" sx={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Text align="center" color="dimmed">
            Please log in to use the AI chat feature
          </Text>
        </Box>
      </Paper>
    );
  }

  if (!context) {
    throw new Error("AIChatWindow must be used within a StoreProvider");
  }
  const userkey = user.key;

  // Fetch dynamic prompts based on the data
  const fetchSuggestedPrompts = async () => {
    setLoadingPrompts(true);
    try {
      const summaryCSV = getNewAPIMapSummaryCSV(context);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}demographics/suggest_prompts`,
        { summary_data: summaryCSV },
        {
          headers: {
            Authorization: `token ${userkey}`,
          },
        }
      );
      if (response.data.prompts) {
        setSuggestedPrompts(response.data.prompts);
      } else {
        // Fallback to default prompts if no data received
        setSuggestedPrompts(DEFAULT_PROMPTS);
      }
    } catch (error) {
      console.error("Error fetching prompts:", error);
      // Fallback to default prompts on error
      setSuggestedPrompts(DEFAULT_PROMPTS);
    } finally {
      setLoadingPrompts(false);
    }
  };

  // Get insights about significant patterns in the data
  const fetchDataInsights = async () => {
    try {
      const summaryCSV = getNewAPIMapSummaryCSV(context);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}demographics/analyze_patterns`,
        { summary_data: summaryCSV },
        {
          headers: {
            Authorization: `token ${userkey}`,
          },
        }
      );
      if (response.data) {
        setAnalysisData(response.data);
      }
    } catch (error) {
      console.error("Error fetching insights:", error);
    }
  };

  const handleSend = async (content: string = input) => {
    if (!content.trim() || loading) return;

    setLoading(true);
    const newMessages = [...messages];

    // Add user message
    newMessages.push({ role: "user", content });
    setMessages(newMessages);
    setInput("");

    try {
      // If this is the first user message, include the CSV data and fetch insights
      if (newMessages.filter((m) => m.role === "user").length === 1) {
        const summaryCSV = getNewAPIMapSummaryCSV(context);
        newMessages.splice(1, 0, {
          role: "user",
          content: "Here is the demographic data in CSV format:\n" + summaryCSV,
        });
        fetchDataInsights();
      }

      // Get follow-up questions based on the current question and context
      const followUpResponse = await axios.post(
        `${process.env.REACT_APP_BASE_URL}demographics/suggest_follow_up`,
        {
          current_question: content,
          conversation_history: newMessages,
        },
        {
          headers: {
            Authorization: `token ${userkey}`,
          },
        }
      );

      let data = new FormData();
      data.append("session_so_far", JSON.stringify(newMessages));

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}demographics/interactive_ai_chat`,
        data,
        {
          headers: {
            Authorization: `token ${userkey}`,
          },
        }
      );

      // Add assistant response
      setMessages([...newMessages, response.data]);

      // Update suggested prompts with follow-up questions
      if (followUpResponse.data.follow_up_questions) {
        setFollowUpResponse({
          questions: followUpResponse.data.follow_up_questions,
          tips: followUpResponse.data.tips || [],
        });
      }
    } catch (error) {
      console.error("Error in AI chat:", error);
      setMessages([
        ...newMessages,
        {
          role: "assistant",
          content: "I apologize, but I encountered an error. Please try again.",
        },
      ]);
    } finally {
      setLoading(false);
    }
  };

  const handleCopy = (content: string) => {
    navigator.clipboard.writeText(content);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSend();
    }
  };

  const resetChat = () => {
    setMessages([
      {
        role: "system",
        content: "You are Bruce, an experienced Australian urban planning consultant with deep expertise in demographic analysis. Use Australian English spelling and terminology (e.g., 'analyse' not 'analyze', 'centre' not 'center'). Maintain a warm and professional tone while providing insightful analysis.",
      },
      {
        role: "assistant",
        content:
          "Hello, I'm Bruce, your urban planning consultant. With years of experience analysing Australian demographics, I'm here to help you understand your selected area. What would you like to know?",
      },
    ]);
  };

  const filteredPrompts =
    activeCategory === "all"
      ? suggestedPrompts
      : suggestedPrompts.filter((p) => p.category === activeCategory);

  const renderMessage = (message: { role: string; content: string }) => {
    if (
      message.role === "assistant" &&
      message.content.includes("interesting patterns")
    ) {
      return null;
    }
    return message.role === "assistant" ? (
      <ReactMarkdown>{message.content}</ReactMarkdown>
    ) : (
      <Text size="sm">{message.content}</Text>
    );
  };

  const renderAnalysisSummary = () => {
    if (!analysisData) return null;

    return (
      <Stack spacing="md">
        <Text size="sm" weight={500}>
          {analysisData.summary}
        </Text>

        {analysisData.insights.map((insight, index) => (
          <Paper key={index} className={classes.insightCard}>
            <Text className={classes.insightPattern}>{insight.pattern}</Text>
            <Text className={classes.insightAction}>{insight.action}</Text>
          </Paper>
        ))}

        {analysisData.opportunities.length > 0 && (
          <Box mt="md">
            <Text weight={500} size="sm" mb="xs">
              Key Opportunities:
            </Text>
            <List size="sm">
              {analysisData.opportunities.map((opp, index) => (
                <List.Item key={index}>{opp}</List.Item>
              ))}
            </List>
          </Box>
        )}
      </Stack>
    );
  };

  const FollowUpBar = ({
    questions,
    tips,
    onClose,
  }: {
    questions: FollowUpQuestion[];
    tips: string[];
    onClose: () => void;
  }) => (
    <Box className={classes.followUpBar}>
      <Group position="apart" mb="xs">
        <Text size="sm" weight={500}>
          Follow-up Questions
        </Text>
        <ActionIcon onClick={onClose} variant="subtle">
          <ChevronDown size={16} />
        </ActionIcon>
      </Group>
      <ScrollArea className={classes.followUpScroll}>
        <Group spacing="sm" noWrap>
          {questions.map((q, index) => (
            <Paper key={index} className={classes.followUpCard} withBorder>
              <Stack spacing="xs">
                <Text size="sm" weight={500} lineClamp={2}>
                  {q.text}
                </Text>
                <Text size="xs" color="dimmed">
                  {q.purpose}
                </Text>
                <Button
                  variant="light"
                  color="orange"
                  size="xs"
                  onClick={() => handleSend(q.text)}
                  fullWidth
                >
                  Ask this
                </Button>
              </Stack>
            </Paper>
          ))}
        </Group>
      </ScrollArea>
      {tips.length > 0 && (
        <Box mt="sm">
          <Text size="xs" weight={500} color="dimmed" mb="xs">
            Tips:
          </Text>
          <Group spacing="xs">
            {tips.map((tip, index) => (
              <Badge key={index} size="sm" variant="dot" color="orange">
                {tip}
              </Badge>
            ))}
          </Group>
        </Box>
      )}
    </Box>
  );

  return (
    <Paper className={classes.chatContainer}>
      <Box className={classes.header}>
        <Group position="apart" align="center">
          <Group spacing="sm">
            <Avatar color="orange" radius="xl">
              <MessageChatbot size={24} />
            </Avatar>
            <Text size="lg" weight={500}>
              Bruce - AI Urban Planner
            </Text>
          </Group>
          <Group spacing="xs">
            {analysisData && (
              <Tooltip label="View Area Analysis">
                <ActionIcon
                  variant="light"
                  color="orange"
                  onClick={() => setShowAnalysis(true)}
                >
                  <ChartInfographic size={18} />
                </ActionIcon>
              </Tooltip>
            )}
            <Tooltip label="Reset conversation">
              <ActionIcon variant="light" color="gray" onClick={resetChat}>
                <Refresh size={18} />
              </ActionIcon>
            </Tooltip>
          </Group>
        </Group>
      </Box>

      <ScrollArea className={classes.messagesArea}>
        <Stack spacing="xs">
          {messages
            .filter(
              (m) =>
                m.role !== "system" &&
                !m.content.includes("demographic data in CSV format")
            )
            .map(
              (message, index) =>
                message.role !== "system" && (
                  <Paper
                    key={index}
                    className={`${classes.message} ${
                      message.role === "user"
                        ? classes.userMessage
                        : classes.botMessage
                    }`}
                  >
                    {renderMessage(message)}
                    <Box className={classes.iconContainer}>
                      <ActionIcon onClick={() => handleCopy(message.content)}>
                        {copied ? (
                          <Check size={16} color="green" />
                        ) : (
                          <Clipboard size={16} color="gray" />
                        )}
                      </ActionIcon>
                    </Box>
                  </Paper>
                )
            )}
          {loading && (
            <Paper className={`${classes.message} ${classes.botMessage}`}>
              <Group spacing="xs">
                <Loader size="xs" color="orange" />
                <Text size="sm" color="dimmed">
                  Thinking...
                </Text>
              </Group>
            </Paper>
          )}
        </Stack>
      </ScrollArea>

      {messages.length <= 2 && (
        <Box p="md">
          <Group position="apart" mb="xs">
            <Text size="sm" color="dimmed">
              Suggested questions:
            </Text>
            {!loadingPrompts && (
              <ScrollArea scrollbarSize={6} type="hover" offsetScrollbars>
                <Group spacing="xs">
                  <Chip.Group
                    value={activeCategory}
                    onChange={(val: string | string[]) =>
                      setActiveCategory(val.toString())
                    }
                  >
                    <Group spacing={4}>
                      {categories.map((category) => (
                        <Chip key={category} value={category} size="xs">
                          {category.charAt(0).toUpperCase() + category.slice(1)}
                        </Chip>
                      ))}
                    </Group>
                  </Chip.Group>
                </Group>
              </ScrollArea>
            )}
          </Group>

          <ScrollArea h={150} offsetScrollbars scrollbarSize={6} type="hover">
            {loadingPrompts ? (
              <Group position="center" py="xl">
                <Loader size="sm" color="orange" />
                <Text size="sm" color="dimmed">Loading suggestions...</Text>
              </Group>
            ) : activeCategory === "all" ? (
              Object.entries(groupedPrompts).map(([category, prompts]) => (
                <Box key={category} mb="md">
                  <Text
                    size="xs"
                    weight={500}
                    color="dimmed"
                    mb="xs"
                    tt="uppercase"
                  >
                    {category}
                  </Text>
                  <Group spacing="xs">
                    {prompts.map((prompt, index) => (
                      <Button
                        key={`${category}-${index}`}
                        variant="default"
                        size="xs"
                        className={classes.suggestionButton}
                        onClick={() => handleSend(prompt.text)}
                        disabled={loading}
                      >
                        {prompt.text}
                      </Button>
                    ))}
                  </Group>
                </Box>
              ))
            ) : (
              <Group spacing="xs">
                {filteredPrompts.map((prompt, index) => (
                  <Button
                    key={index}
                    variant="default"
                    size="xs"
                    className={classes.suggestionButton}
                    onClick={() => handleSend(prompt.text)}
                    disabled={loading}
                  >
                    {prompt.text}
                  </Button>
                ))}
              </Group>
            )}
          </ScrollArea>
        </Box>
      )}

      {showFollowUp && followUpResponse && (
        <FollowUpBar
          questions={followUpResponse.questions}
          tips={followUpResponse.tips}
          onClose={() => setShowFollowUp(false)}
        />
      )}

      <Box className={classes.inputArea}>
        <Group spacing="xs" align="flex-start">
          <Textarea
            placeholder="Type your question..."
            value={input}
            onChange={(e) => setInput(e.currentTarget.value)}
            onKeyDown={handleKeyDown}
            disabled={loading}
            autosize
            minRows={1}
            maxRows={4}
            sx={{ flex: 1 }}
          />
          <Button
            color="orange"
            onClick={() => handleSend()}
            disabled={!input.trim() || loading}
            sx={{ alignSelf: "stretch", display: "flex", alignItems: "center" }}
          >
            {loading ? <Loader size="xs" color="white" /> : <Send size={16} />}
          </Button>
        </Group>
      </Box>

      {/* Analysis Modal */}
      <Modal
        opened={showAnalysis}
        onClose={() => setShowAnalysis(false)}
        title="Area Analysis"
        size="lg"
      >
        <Paper className={classes.summaryModal}>
          {renderAnalysisSummary()}
        </Paper>
      </Modal>
    </Paper>
  );
};

export default AIChatWindow;
