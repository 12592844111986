import { useContext, useEffect, useState } from "react";
import storeContext from "../../store/store";
import "@maptiler/sdk/dist/maptiler-sdk.css";

type mapBounds = {
  minx: number;
  miny: number;
  maxx: number;
  maxy: number;
};

type center = {
  lat: number;
  lng: number;
};

type mapProps = {
  readonly polygonList: any;
  readonly paddingPercent?: Array<number>;
  readonly context?: any;
  readonly callback?: (width: number, height: number) => void;
};

function ExportMapComponent(props: mapProps) {
  const context = useContext(storeContext);
  const { dispatch } = props.context ? props.context : context;

  const [imageUrl, setImageUrl] = useState<string>();
  const [imageoverlay, setImageOverlay] = useState<string>();
  const [imageError, setImageError] = useState<boolean>(false);
  const [overlayLoading, setOverlayLoading] = useState<boolean>(true);
  const [imageLoading, setImageLoading] = useState<boolean>(true);
  const [exportSizeSet, setExportSizeSet] = useState(false);
  const [dims, setDims] = useState({ width: 0, height: 0 });

  const polygonList = props.polygonList;
  const paddingPercent = props.paddingPercent ?? [25, 25, 25, 25];

  let mapImageWidth = 1000;
  let mapImageHeight = 680;

  const handleImageError = () => {
    console.log("Error loading image");
    setImageError(true);
  };

  const loadMap = async () => {
    try {
      const apiKey = process.env.REACT_APP_MAPTILER_API_KEY;
      if (!apiKey) {
        throw new Error("MapTiler API key is not configured");
      }

      let minLat = Infinity;
      let minLng = Infinity;
      let maxLat = -Infinity;
      let maxLng = -Infinity;

      // Check if polygonList is empty
      if (!polygonList || polygonList.length === 0) {
        throw new Error("No polygons provided for map export");
      }

      // iterate over all polygons
      for (let i = 0; i < polygonList.length; i++) {
        let polygonMVCArray =
          polygonList[i].polygon !== undefined
            ? polygonList[i].polygon.getPath()
            : polygonList[i].getPath();

        if (!polygonMVCArray) {
          console.warn(`Polygon at index ${i} has no path`);
          continue;
        }

        // eslint-disable-next-line no-loop-func
        polygonMVCArray.forEach((element: any) => {
          const lat = element.lat();
          const lng = element.lng();

          // update min and max values
          minLat = Math.min(minLat, lat);
          minLng = Math.min(minLng, lng);
          maxLat = Math.max(maxLat, lat);
          maxLng = Math.max(maxLng, lng);
        });
      }

      // Validate bounds
      if (minLat === Infinity || minLng === Infinity || maxLat === -Infinity || maxLng === -Infinity) {
        throw new Error("Invalid polygon bounds calculated");
      }

      const latRange = maxLat - minLat;
      const lngRange = maxLng - minLng;

      let topPadding = latRange * (paddingPercent[0] / 100);
      let rightPadding = lngRange * (paddingPercent[1] / 100);
      let bottomPadding = latRange * (paddingPercent[2] / 100);
      let leftPadding = lngRange * (paddingPercent[3] / 100);

      //calculate the ratio of the map after projection
      let mapRatio =
        (lon2x(maxLng) - lon2x(minLng)) / (lat2y(maxLat) - lat2y(minLat));

      // if map width is 2x the height, then increase padding on the height to 150% of the distance between min and max
      if (mapRatio > 2) {
        topPadding = latRange * 0.75;
        bottomPadding = latRange * 0.75;
      }

      // update min and max values
      minLat -= bottomPadding;
      minLng -= leftPadding;
      maxLat += topPadding;
      maxLng += rightPadding;

      // create the bounds object
      const bounds: mapBounds = {
        minx: minLng,
        miny: minLat,
        maxx: maxLng,
        maxy: maxLat,
      };

      const projectedBounds = {
        minx: lon2x(bounds.minx),
        miny: lat2y(bounds.miny),
        maxx: lon2x(bounds.maxx),
        maxy: lat2y(bounds.maxy),
      };

      // calculate the center of the bounds
      const centerLat = (maxLat + minLat) / 2;
      const centerLng = (maxLng + minLng) / 2;

      // set the center
      const centerLatlng: center = { lat: centerLat, lng: centerLng };

      // compute the aspect ratio of the bounds based on mercator projection
      const boundsAspectRatio =
        (projectedBounds.maxx - projectedBounds.minx) /
        (projectedBounds.maxy - projectedBounds.miny);

      // compute the height based on the aspect ratio
      mapImageHeight = Math.round(mapImageWidth / boundsAspectRatio);

      // if image is too tall, then set the height to the max height and compute the width
      if (mapImageHeight > 2048) {
        mapImageHeight = 2048;
        mapImageWidth = Math.round(mapImageHeight * boundsAspectRatio);
      }

      // if dispatch available, then set the map image dimensions
      try {
        await dispatch({
          type: "setHeatMapExportSize",
          payload: { width: mapImageWidth, height: mapImageHeight },
        });
        setExportSizeSet(true);
      } catch (error) {
        console.error("Error dispatching map size:", error);
        setExportSizeSet(false);
      }

      // Construct and validate URL
      const baseUrl = "https://api.maptiler.com/maps/0195da4e-4423-7200-b506-fd570e0bb4b2/static";
      const coordinates = `${minLng.toFixed(6)},${minLat.toFixed(6)},${maxLng.toFixed(6)},${maxLat.toFixed(6)}`;
      const dimensions = `${mapImageWidth}x${mapImageHeight}`;
      const url = `${baseUrl}/${coordinates}/${dimensions}.png?key=${apiKey}&padding=0`;

      console.log("Generated map URL:", url);

      // Add logging for URL generation success
      console.log("Successfully generated map configuration:", {
        imageUrl: url,
        dimensions: `${mapImageWidth}x${mapImageHeight}`,
        bounds,
        center: centerLatlng
      });

      setImageUrl(url); // Explicitly set the URL here as well

      return {
        imageUrl: url,
        bounds: bounds,
        center: centerLatlng,
        mapImageWidth,
        mapImageHeight,
      };
    } catch (error) {
      console.error("Error in loadMap:", error);
      setImageError(true);
      throw error;
    }
  };

  function hexToRgb(hex: string): Array<number> {
    // if string "green" return rgb(0,128,0) - For filter polygons
    if (hex === "green") {
      return [0, 128, 0];
    }

    let r: any = 0,
      g: any = 0,
      b: any = 0;
    hex = hex.replace(/^#/, "");

    if (hex.length === 3) {
      hex = hex.replace(/(.)/g, "$1$1");
    }

    r = parseInt(hex.substring(0, 2), 16);
    g = parseInt(hex.substring(2, 4), 16);
    b = parseInt(hex.substring(4, 6), 16);

    return [r, g, b];
  }

  const drawPolygons = (bounds: mapBounds, center: center) => {
    const canvas = document.getElementById("mapCanvas") as HTMLCanvasElement;
    if (!canvas) {
      console.error("Canvas element not found");
      return;
    }

    canvas.width = mapImageWidth;
    canvas.height = mapImageHeight;

    const context = canvas.getContext("2d");
    if (!context) {
      console.error("Context not found");
      return;
    }

    try {
      // Draw all polygons
      for (let i = 0; i < polygonList.length; i++) {
        drawPolygon(
          polygonList[i].polygon !== undefined ? polygonList[i].polygon : polygonList[i],
          bounds,
          context,
          center
        );
      }

      // Get the data URL before removing the canvas
      const dataURL = canvas.toDataURL();
      setImageOverlay(dataURL);
      setOverlayLoading(false);

      // Only remove the canvas after we're done with it
      if (canvas.parentNode) {
        canvas.parentNode.removeChild(canvas);
      }
    } catch (error) {
      console.error("Error in drawPolygons:", error);
      setImageError(true);
      setOverlayLoading(false);
    }
  };

  async function drawPolygon(
    polygon: any,
    bounds: mapBounds,
    context: any,
    center: center
  ): Promise<void> {
    try {
      let polygonMVCArray = polygon.getPath();
      if (polygonMVCArray) {
        let polygonPoints: Array<[number, number]> = [];

        polygonMVCArray.forEach((element: any) => {
          const lat = element.lat().toFixed(5);
          const lng = element.lng().toFixed(5);
          const latFloat = parseFloat(lat);
          const lngFloat = parseFloat(lng);

          polygonPoints.push([lngFloat, latFloat]);
        });

        let fillColor = hexToRgb(polygon.fillColor);

        // draw the polygon on the mapImage canvas with converted coordinates
        context.beginPath();

        let convertedPoint = latLngToPixel(
          polygonPoints[0][1],
          polygonPoints[0][0],
          mapImageWidth,
          mapImageHeight,
          bounds,
          center
        );

        context.moveTo(convertedPoint.x, convertedPoint.y);

        for (let j = 1; j < polygonPoints.length; j++) {
          convertedPoint = latLngToPixel(
            polygonPoints[j][1],
            polygonPoints[j][0],
            mapImageWidth,
            mapImageHeight,
            bounds,
            center
          );
          context.lineTo(convertedPoint.x, convertedPoint.y);
        }

        context.strokeStyle = "grey";
        context.lineWidth = 4;
        context.stroke();
        context.fillStyle = `rgba(${fillColor[0]},${fillColor[1]},${fillColor[2]},0.1)`;
        context.fill();
      }
    } catch (error) {
      console.error("Error in drawPolygon:", error);
      throw error;
    }
  }

  function latLngToPixel(
    lat: number,
    lng: number,
    mapImageWidth: number,
    mapImageHeight: number,
    bounds: mapBounds,
    center: center
  ): { x: number; y: number } {
    // Here's the Mercator projection for the input coordinates
    let [x, y] = [lon2x(lng), lat2y(lat)];

    // Now we do the Mercator projection for the bounds and center
    let [centerX, centerY] = [lon2x(center.lng), lat2y(center.lat)];
    let [minX, maxX, minY, maxY] = [
      lon2x(bounds.minx),
      lon2x(bounds.maxx),
      lat2y(bounds.miny),
      lat2y(bounds.maxy),
    ];

    // Adjusting input coords relative to map center
    let deltaLat = y - centerY;
    let deltaLng = x - centerX;

    // Normalizing to range [-1,1]
    deltaLat /= (maxY - minY) / 2;
    deltaLng /= (maxX - minX) / 2;

    // Assuming centered at (0,0), scale back to image scale and translate to image center
    x = deltaLng * (mapImageWidth / 2) + mapImageWidth / 2;

    // Flip latitude to match image coordinate space (top left origin)
    y = mapImageHeight / 2 - deltaLat * (mapImageHeight / 2);

    return { x, y };
  }

  const PI = Math.PI;
  const RAD2DEG = 180 / PI;
  const DEG2RAD = PI / 180;
  const R = 6378137.0;

  function y2lat(y: number) {
    return (2 * Math.atan(Math.exp(y / R)) - PI / 2) * RAD2DEG;
  }
  function x2lon(x: number) {
    return RAD2DEG * (x / R);
  }

  function lat2y(lat: number) {
    return Math.log(Math.tan(PI / 4 + (lat * DEG2RAD) / 2)) * R;
  }
  function lon2x(lon: number) {
    return lon * DEG2RAD * R;
  }

  useEffect(() => {
    async function initMapAndPolygons() {
      try {
        const { imageUrl, bounds, center, mapImageWidth, mapImageHeight } = await loadMap();
        console.log("Load map success")
        setImageUrl(imageUrl);

        const image = new Image();
        console.log("Start Image")

        image.onload = () => {
          setImageLoading(false);
          drawPolygons(bounds, center);
          setExportSizeSet(true);
        };
        image.onerror = () => {
          handleImageError();
          setImageLoading(false);
        };
        image.src = imageUrl;

        setDims({ width: mapImageWidth, height: mapImageHeight });
      } catch (error) {
        console.log("Error in loadMap", error)
        console.error(error);
        setImageLoading(false);
        setExportSizeSet(false);
      }
    }

    initMapAndPolygons();
  }, []);

  useEffect(() => {
    if (exportSizeSet && !imageLoading && !overlayLoading) {
      if (props.callback) {
        props.callback(dims.width, dims.height);
        setExportSizeSet(false);
        setImageLoading(true);
        setOverlayLoading(true);
      }
    }
  }, [dims.width, dims.height, exportSizeSet, imageLoading, overlayLoading, props]);

  return (
    <div
      id="exportmapComponent"
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
        objectFit: "contain",
      }}
    >
      {imageError && <div>Image Error</div>}
      <div>
        <img
          id="mapImage"
          alt="Map"
          src={imageUrl}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "auto",
            objectFit: "contain",
            zIndex: 1,
          }}
        />
        <img
          id="mapOverlay"
          alt="Map Polygon Overlay"
          src={imageoverlay}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "auto",
            objectFit: "contain",
            zIndex: 2,
          }}
        />
        <canvas
          id="mapCanvas"
          style={{
            width: "100%",
            height: "auto",
            zIndex: 3,
            backgroundColor: "transparent",
          }}
        />
      </div>
    </div>
  );
}

export default ExportMapComponent;
