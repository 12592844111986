import {
  Button,
  createStyles,
  Group,
  Modal,
  Skeleton,
  Text,
} from "@mantine/core";
import { Table } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import axios from "axios";
import { FC, SetStateAction, useState } from "react";
import { Check, X } from "tabler-icons-react";
import { useAuth } from "../hooks/useAuth";
import { TeamMemberType } from "../types";
// import MyMapsListSkeleton from "./MyMapsListSkeleton";

const useStyles = createStyles((theme) => ({
  upgradeNowBtn: {
    color: theme.colors.allColors[7],
    backgroundColor: theme.colors.allColors[0],

    "&:hover": {
      backgroundColor: theme.colors.allColors[7],
      color: "white",
    },
    [`@media (min-width: ${theme.breakpoints.lg}px)`]: {
      minWidth: "208px",
    },
  },
  createNewBtn: {
    color: theme.colors.allColors[0],
    backgroundColor: theme.colors.allColors[7],
    "&:hover": {
      backgroundColor: theme.colors.allColors[7],
      color: "white",
    },
    [`@media (min-width: ${theme.breakpoints.lg}px)`]: {
      minWidth: "208px",
    },
  },
  tableBody: {
    textAlign: "start",
  },
  boundaryMap: {
    display: "flex",
    alignItems: "center",
  },
  iconDiv: {
    marginRight: "17px",
  },
  userCreation: {
    display: "flex",
    alignItems: "center",
  },
  textDiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
  },

  mapDetails: {
    color: theme.colors.allColors[8],
    opacity: ".54",
  },
  author: {
    fontSize: 16,
    fontWeight: "bold",
    color: theme.colors.allColors[8],
  },
  createdAt: {
    color: theme.colors.allColors[8],
    opacity: ".54",
  },
  updatedAt: {
    color: theme.colors.allColors[8],
  },
  imgDiv: {
    marginRight: "10px",
  },
  letterAvatar: {
    backgroundColor: theme.colors.allColors[3],
    color: "black",
  },
  menuItem: {
    color: "blue !important",
  },
  menu: {
    color: "blue !important",
  },
  tableMap: {
    minWidth: "500px",
  },
  bodyText: {
    lineHeight: "26.8px",
    marginBottom: 25,
  },
  tableBodyCell: {
    padding: "0px 10px !important",
  },
}));

type Props = {
  members: TeamMemberType[];
  orgAdmin: boolean;
  isLoad: boolean;
  setMembers: React.Dispatch<SetStateAction<TeamMemberType[]>>;
};
const TeamList: FC<Props> = ({
  members,
  orgAdmin,
  isLoad,
  setMembers,
}: Props) => {
  const { classes } = useStyles();
  const { user: loggedInUser } = useAuth();
  const [selectedMemberId, setSelectedMemberId] = useState<string | null>(null);

  const removeTeamMember = async (memberId: string, memberName: string) => {
    try {
      const formData = new FormData();
      formData.append("user_id", memberId);
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}subscriptions/remove_user_from_team/`,
        data: formData,
        headers: {
          Authorization: `token ${loggedInUser.key}`,
        },
      });
      if (response.status === 200) {
        showNotification({
          id: "member-rem-notif",
          title: "Team Member Removal!",
          message: `${
            response.data.detail || `${memberName} removed successfully`
          }! `,
          autoClose: 3000,
          color: "green",
          icon: <Check />,
          styles: (theme) => ({
            root: {
              backgroundColor: theme.white,
              borderColor: "green",

              "&::before": { backgroundColor: theme.white },
            },

            title: { color: "black" },
            description: { color: "black" },
            closeButton: {
              color: theme.black,
            },
          }),
        });
        let filteredMembers = members.filter(
          (member: any) => member.id !== memberId
        );
        setMembers(filteredMembers);
      }
    } catch (error) {
      showNotification({
        id: "err-notification",
        title: "Request Failed!",
        message: `${"Please try again"}!`,
        autoClose: 3000,
        color: "red",
        icon: <X />,
        styles: (theme) => ({
          root: {
            backgroundColor: theme.white,
            borderColor: "red",

            "&::before": { backgroundColor: theme.white },
          },

          title: { color: "black" },
          description: { color: "black" },
          closeButton: {
            color: theme.black,
          },
        }),
      });
      console.log("error in removing member", error);
    }
    setSelectedMemberId(null);
  };
  const ths = (
    <tr>
      <th>MEMBER</th>
      <th>MAPS</th>
      <th></th>
    </tr>
  );

  const rows = members.map((member: any) => (
    <tr key={member.id}>
      <td className={classes.tableBodyCell}>
        <div className={classes.userCreation}>
          <div className={classes.textDiv}>
            {isLoad ? (
              <span className={classes.author}> {member.username}</span>
            ) : (
              <Skeleton height={8} width="20%" />
            )}
          </div>
        </div>
      </td>
      <td className={`${classes.updatedAt} ${classes.tableBodyCell}`}>
        {member.map_count}
      </td>
      <td style={{ textAlign: "right" }} className={classes.tableBodyCell}>
        <Modal
          opened={selectedMemberId === member.id}
          onClose={() => setSelectedMemberId(null)}
          title="Remove Team Member!"
          centered
          padding={0}
          overlayProps={{ opacity: 0.3, color: "black" }}
        >
          <>
            <Text size="md" color="#777777">
              Are you sure to remove {member.username} from team?
            </Text>
            <Group position="right" pt={15}>
              <Button
                style={{ minWidth: "130px" }}
                variant="outline"
                onClick={() => setSelectedMemberId(null)}
              >
                No
              </Button>
              <Button
                style={{ minWidth: "130px" }}
                variant="filled"
                onClick={() => removeTeamMember(member.id, member.username)}
              >
                Yes
              </Button>
            </Group>
          </>
        </Modal>
        {orgAdmin && loggedInUser.user_id !== member.id && (
          <Button
            color="gray"
            variant="outline"
            onClick={() => setSelectedMemberId(member.id)}
          >
            Remove From Team
          </Button>
        )}
        {!orgAdmin && loggedInUser.user_id !== member.id && (
          <h3>Team member</h3>
        )}
        {loggedInUser.user_id === member.id && <h3>You</h3>}
      </td>
    </tr>
  ));

  return (
    <div className="table-responsive">
      <Table
        className="table-inner"
        verticalSpacing="sm"
        captionSide="bottom"
        mb="xl"
        mt="sm"
        pb="lg"
      >
        <thead>{ths}</thead>
        <tbody className={classes.tableBody}>{rows}</tbody>
      </Table>
    </div>
  );
};

export default TeamList;
